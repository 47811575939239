import React from "react"
import Home from '../pages/home'
import '../style/styles.css'
import { Helmet } from "react-helmet"

const IndexPage = () => {

  return(
    <>
      <Home />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wow Solution</title>
        <link rel="canonical" href="https://www.wowsolution.com/" />
      </Helmet>
    </>
  )

}

export default IndexPage
